import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

// Icons
import { FaLinkedin } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaHome } from 'react-icons/fa'

const PortfolioPage = () => {
  return (
	<Layout>
		<main>
			<section className="projects">
                <Link to="https://aleph-farms.com/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/aleph.jpg"
                        alt="Aleph Farms Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="https://habitatnorthwoods.org/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/h4h.jpg"
                        alt="Habitat For Humanity Northwoods Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="#" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/rtc.jpg"
                        alt="Richmond Terrace Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="https://pinnacleeggbank.com/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/peb.jpg"
                        alt="Pinnacle Egg Bank Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="https://www.charteroakhospital.com/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/co.jpg"
                        alt="Charter Oak Hospital Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="https://www.ocspecialtyhealth.com/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/oc.jpg"
                        alt="Orange County Speciality Hospital Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="https://markpieplow.com/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/mp.jpg"
                        alt="Mark Pieplow Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
                <Link to="https://colonialdentalplan.com/" target="_blank" className="project" rel="noreferrer noopener">
                    <StaticImage
                        src="../images/cdp.jpg"
                        alt="Colonial Dental Plan Screenshot"
                        placeholder="none"
                        layout="constrained"
                    />
                </Link>
            </section>
            <section className="portfolio-footer">
                <a className="button" href="/"><FaHome /> Home</a>
                <a className="button" href="https://www.linkedin.com/in/dylancliff/" target="_blank"><FaLinkedin /> LinkedIn</a>
                <a className="button" href="/contact/"><FaEnvelope /> Contact Me</a>
            </section>
		</main>
	</Layout>
  )
}
export default PortfolioPage

export const Head = () => (
	<SEO />
)
